<template>
  <div id="institute_intro">
    <!--  头部banner区域  -->
    <div class="top_banner">
      <img src="@/assets/images/institute/institute_top_banner.png" alt="" />
      <div class="top_title">
        <div>
          <h3>晓羊教育研究院</h3>
          <!-- <p>Xiaoyang Education Research Institute</p> -->
        </div>
      </div>
    </div>
    <!--  内容区域  -->
    <div class="content">
      <!-- 研究院概况  -->
      <div class="intro">
        <div class="title">
          <h3 id="overview">研究院概况</h3>
          <!-- <p class="title_en">Introduction To The Institute</p> -->
        </div>
        <div class="intro_content">
          <!-- <p>
            隶属于北京晓羊科技集团有限公司，是基础教育改革与创新理论研究、区域和学校教育改革实践探索、课程与课堂教学改革指导的专业学术机构。
          </p> -->
          <div class="card">
            <div>
              <i class="iconfont icon-boxes-fill"></i>
              <div>
                <p class="pc">
                  隶属于北京晓羊科技集团有限公司，是基础教育改革与创新理论研究、区域和学校教育改革实践探索、课程与课堂教学改革指导的专业学术机构。
                </p>
                <p class="pc">
                  研究院拥有专兼职专家50余位，包含教育研究和中小学教育教学实践领域的顶级专家，也包含杰出的一线校长、名师，具备卓越的中小学教育教学研究与教育咨询服务的专业能力和经验，为基础教育领域的教育局、中小学幼儿园提供科学、高端、实用的专业咨询服务。研究院先后为内蒙古、江西、山西、青海等省级单位以及数十个市县做了新高考调研，并出具专业的调研评估报告，获得教育行政主管部门的高度认可。同时，研究院在深度调研的基础上为内蒙古乌海市起草了新课程新教材国家级实验区三年实施方案和一年行动计划，并且深度支持了内蒙古乌海六中、乌海二中、乌海十八中、桂林十九中的课堂教学改革，取得了显著成效。
                </p>
                <p class="pc">
                  晓羊教育研究院将持续致力于基础教育改革创新，力争成为教育局、学校的“外脑”智库，为新时代教育发展和人才强国伟业贡献力量！
                </p>
                <p class="mobile">
                  隶属于北京晓羊科技集团有限公司，是基础教育改革与创新理论研究、区域和学校教育改革实践探索、课程与课堂教学改革指导的专业学术机构。
                </p>
                <p class="mobile">
                  研究院拥有专兼职专家50余位，包含教育研究和中小学教育教学实践领域的顶级专家，也包含杰出的一线校长、名师，具备卓越的中小学教育教学研究与教育咨询服务的专业能力和经验，为基础教育领域的教育局、中小学幼儿园提供科学、高端、实用的专业咨询服务。研究院先后为内蒙古、江西、山西、青海等省级单位以及数十个市县做了新高考调研，并出具专业的调研评估报告，获得教育行政主管部门的高度认可。同时，研究院在深度调研的基础上为内蒙古乌海市起草了新课程新教材国家级实验区三年实施方案和一年行动计划，并且深度支持了内蒙古乌海六中、乌海二中、乌海十八中、桂林十九中的课堂教学改革，取得了显著成效。
                </p>
                <p class="mobile">
                  晓羊教育研究院将持续致力于基础教育改革创新，力争成为教育局、学校的“外脑”智库，为新时代教育发展和人才强国伟业贡献力量！
                </p>
              </div>
            </div>
            <img src="@/assets/images/institute/intro_photo.png" alt="" />
          </div>
        </div>
      </div>
      <!-- 资源优势 -->
      <div class="specialty">
        <div class="title">
          <h3 id="resource">优势</h3>
          <!-- <p class="title_en">Professional Advantage</p> -->
        </div>
        <ul class="specialty_content">
          <li>
            <img src="@/assets/images/institute/specialist.png" alt="" />
            <div>
              <h4 class="color-primary specItem">专家优势</h4>
              <p>
                中国管理科学研究院基础教育研究所研究员刘文江出任院长，汇集50余位全国知名教授学者、教育科研人员、全国中小学校长和教师团队等核心专家，长期合作的专家、校长、教师超300位。
              </p>
            </div>
          </li>

          <li>
            <img src="@/assets/images/institute/technology.png" alt="" />
            <div>
              <h4 class="color-primary specItem">技术优势</h4>
              <p>
                由拥有美国特拉华大学人工智能博士学位的周炜博士领衔、汇聚了近百位研发人员组成的国际专业的技术研发团队，中美欧三地算法中心支持，研发教育专属数据中台、“一人一课表”排课系统、区域课程管理平台、五育评价系统、教师专业发展平台、区域质量评价系统等，
                解决中小学教育教学管理的实际需求.
              </p>
            </div>
          </li>
          <li>
            <img src="@/assets/images/institute/source.jpg" alt="" />
            <div>
              <h4 class="color-primary specItem">资源优势</h4>
              <p>
                全国10000余所合作校（国家级示范校及省级示范校300所），覆盖全国三十一个省份。
              </p>
            </div>
          </li>
          <li>
            <img src="@/assets/images/institute/team.png" alt="" />
            <div>
              <h4 class="color-primary specItem">实施团队</h4>
              <p>
                以“双一流”、985、211名校毕业生为主体的高度专业化人才队伍，具备多年一线教学经历和培训组织经验，为教育局、学校提供专业化、高水准服务。
              </p>
            </div>
          </li>
        </ul>
      </div>
      <!-- 核心业务 -->
      <div class="core">
        <div class="title">
          <h3 id="core" class="pc">核心业务——教育咨询和教育研修服务</h3>
          <h3 id="core" class="mobile">
            核心业务 <br />教育咨询和教育研修服务
          </h3>
          <!-- <p class="title_en">Core Business</p> -->
        </div>
        <ul class="core_content">
          <li>
            <i>01</i>
            <div>
              <h4 class="specItem">教育政策研究</h4>
              <!-- <p>Education Policy Research</p> -->
            </div>
          </li>
          <li>
            <i>02</i>
            <div>
              <h4 class="specItem">教育改革咨询</h4>
              <!-- <p>Education Reform Consulting</p> -->
            </div>
          </li>
          <li>
            <i>03</i>
            <div>
              <h4 class="specItem">教育信息化建设规划</h4>
              <!-- <p>Educational Information Construction Plan</p> -->
            </div>
          </li>
          <li>
            <i>04</i>
            <div>
              <h4 class="specItem">教育教学实践指导</h4>
              <!-- <p>Education And Teaching Practice Guidance</p> -->
            </div>
          </li>
        </ul>
      </div>
      <!--  教育研修项目类型  -->
      <div class="edu_type">
        <div class="title">
          <h3 id="edu_type">教育研修项目类型</h3>
          <!-- <p class="title_en">Internal Journal</p> -->
        </div>
        <ul class="edu_type_content">
          <!--          <img src="@/assets/images/institute/edu_content.png" alt="" />-->
          <li>高考改革调研评估咨询</li>
          <li>中高考改革系列转向研修</li>
          <li>校长、骨干教师、班主任挂职研修</li>
          <li>信息技术与教学深度融合研修</li>
          <li>走班教学管理专题研修</li>
          <li>“生涯规划指导师”认证培训</li>
          <li>中小学课堂教学改革指导</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Institute',
  data() {
    return {}
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
%intro {
  .bg-primary {
    background-color: $--color-primary !important;
  }
  .top_banner {
    position: relative;
    margin-bottom: 50px;
    img {
      width: 100%;
    }
    .top_title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 42px;
      background-color: rgba(0, 0, 0, 0.5);
      color: $--color-white;
      text-align: center;
      h3 {
        margin: 0;
        font-size: 56px;
        @include px2vw('font-size', 56);
      }
      p {
        margin-bottom: 0;
        font-size: 32px;
        @include px2vw('font-size', 32);
      }
    }
  }
  .content {
    width: 90%;
    max-width: 1320px;
    margin: 0 auto;
    // 通用头部样式
    .title {
      text-align: center;
      color: #333;
      h3 {
        line-height: 36px;
        font-size: 36px;
        font-weight: bold;
        margin: 0;
      }
      .title_en {
        font-size: 24px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    // 研究院简介
    .intro {
      &_content {
        margin-top: 60px;
        & > p {
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          // margin-bottom: 42px;
        }
        .card {
          width: 100%;
          box-sizing: border-box;
          padding: 60px 100px;
          box-shadow: 0px 10px 40px 0px rgba(0, 2, 46, 0.08);
          border-radius: 24px;
          margin-bottom: 50px;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          & > div {
            width: 60%;
            margin-right: 5%;
            i.iconfont {
              display: block;
              width: 46px;
              height: 46px;
              background-color: $--color-primary;
              color: $--color-white;
              font-size: 34px;
              text-align: center;
              line-height: 46px;
              border-radius: 16px;
              margin-bottom: 26px;
            }
            p {
              line-height: 32px;
              font-size: 18px;
              text-align: justify;
              color: #666;
            }
          }
          img {
            width: 35%;
            margin-top: 80px;
          }
        }
      }
    }
    // 专业优势
    .specialty {
      margin-bottom: 50px;
      &_content {
        margin-top: 60px;
        display: flex;
        justify-content: space-between;
        height: max-content;
        li {
          width: 23%;
          box-shadow: 0px 2px 24px 0px rgba(184, 184, 184, 0.5);
          img {
            width: 100%;
          }
          div {
            padding: 16px 33px;
            h4 {
              font-size: 20px;
            }
            p {
              font-size: 14px;
              line-height: 28px;
              text-align: justify;
            }
          }
        }
      }
    }
    // 核心业务
    .core {
      margin-bottom: 50px;
      .title {
        margin-bottom: 0;
      }
      &_content {
        display: grid;
        grid-template-columns: repeat(4, 25%);
        li {
          position: relative;
          width: 100%;
          box-sizing: border-box;
          text-align: center;
          height: 410px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0px 10px 40px 0px rgba(0, 2, 46, 0.08);
          border-radius: 24px;
          margin-top: 60px;
          i {
            position: absolute;
            right: 0px;
            top: 0;
            width: 86px;
            height: 86px;
            border-radius: 0 16px;
            background-color: $--color-primary;
            font-size: 32px;
            line-height: 86px;
            color: $--color-white;
          }
          div {
            width: 90%;
            h4 {
              font-size: 22px;
              margin-bottom: 0;
            }
            p {
              font-size: 16px;
            }
          }
          &:nth-child(3),
          &:nth-child(4) {
            div {
              h4 {
                // margin-top: 48px;
              }
            }
          }
        }
      }
    }
    // 教育研修项目类型
    .edu_type {
      margin-bottom: 100px;
      padding-top: 90px;
      background: url('~@/assets/images/institute/bg_edu_type.png') 100% 100%
        no-repeat;
      &_content {
        margin-top: 60px;
        padding-bottom: 128px;
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        border-top: 4px solid $--color-primary;

        img {
          width: 100%;
        }

        li {
          font-size: 18px;
          padding: 45px 20px;
          box-sizing: border-box;
          background-color: $--color-white;
          text-align: center;
          border: 1px solid #ccc;
          &:nth-child(1),
          &:nth-child(3),
          &:nth-child(2) {
            //background-color: #409eff;
            grid-column-start: span 4;
          }
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7) {
            //background-color: #409eff;
            grid-column-start: span 3;
          }
        }
      }
    }

    @media screen and (max-width: 1000px) {
      .intro {
        &_content {
          .card {
            display: block;
            & > div {
              width: 100%;
              i {
                margin: 0 auto;
              }
              ul {
                justify-content: center;
              }
            }
            img {
              margin-top: 40px;
              width: 100%;
            }
          }
        }
      }
      .specialty {
        &_content {
          display: block;
          li {
            width: 100%;
            margin-bottom: 60px;
            div {
              h4 {
                text-align: center;
                font-size: 32px;
              }
              p {
                font-size: 18px;
              }
            }
          }
        }
      }
      .core {
        &_content {
          grid-template-columns: repeat(2, 50%);
        }
      }
      .edu_type {
        &_content {
          li {
            &:nth-child(1),
            &:nth-child(3),
            &:nth-child(2) {
              grid-column-start: span 4;
            }
            &:nth-child(4) {
              grid-column-start: span 12;
            }

            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7) {
              grid-column-start: span 4;
            }
          }
        }
      }
    }
  }
}

.view-pc > #institute_intro {
  @extend %intro;
  .pc {
    display: inherit;
  }
  .mobile {
    display: none;
  }
  .top_banner {
    margin-top: 125px;
  }
  @media screen and (max-width: 1200px) {
    .content {
      .intro {
        .card {
          padding: 40px;
          img {
            width: 300px;
            display: block;
            margin: 20px auto;
          }
        }
      }
    }
    .specialty {
      width: 90%;
      margin: 0 auto;
    }
    .core {
      width: 90%;
      margin: 0 auto;
    }
    .edu_type {
      width: 90%;
      margin: 0 auto;
    }
  }
  @media (min-width: 570px) and(max-width:1440px) {
    .core {
      &_content {
        li {
          margin-top: 60px !important;
          h4 {
            font-size: 24px !important;
          }
          &:nth-child(1) {
            h4 {
              // margin-top: 43px;
            }
          }
          &:nth-child(2) {
            h4 {
              // margin-top: 62px;
            }
          }
        }
      }
    }
    .specialty {
      &_content {
        li {
          div {
            padding: 16px 25px !important;
          }
        }
      }
    }
  }
}

.view-mobile > #institute_intro {
  @extend %intro;
  .pc {
    display: none;
  }
  .mobile {
    display: inherit;
  }
  margin-top: 44px;
  .top_banner {
    margin-bottom: 40px;
    display: flex;
    .top_title {
      width: 100%;
      height: 100%;
      padding: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        // position: absolute;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.5);
        padding: 20px 40px;
        h3 {
          font-size: 20px;
        }
        p {
          margin-bottom: 0;
          font-size: 16px;
        }
      }
    }
  }
  .specItem {
    font-size: 18px !important;
  }
  .content {
    width: 90%;
    margin: 0 auto;
    .title_en {
      font-size: 14px !important;
      margin-top: 10px;
    }
    h3,
    h4 {
      line-height: 30px !important;
    }
    .title {
      text-align: center;
      color: #333;
      h3 {
        line-height: 60px;
        font-size: 40px;
        font-weight: bold;
        margin: 0;
      }
      .title_en {
        font-size: 18px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    .intro {
      &_content {
        margin-top: 30px;
        .card {
          padding: 20px;
          margin-bottom: 30px;
          > div {
            p {
              font-size: 14px;
              line-height: 28px;
              margin: 0 auto 14px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          img {
            margin-top: 30px;
          }
        }
      }
    }
    .specialty {
      margin-bottom: 30px;
      &_content {
        margin-top: 30px;
      }
      h4 {
        margin: 0;
      }
      p {
        color: #666;
        font-size: 14px !important;
      }
    }
    .edu_type {
      padding-top: 40px;
      &_content {
        li {
          &:nth-child(n) {
            grid-column-start: span 12;
          }
        }
      }
    }
    .core {
      &_content {
        display: flex;
        flex-wrap: wrap;
        h4 {
          // font-size: 20px !important;
        }
        .specItem {
          font-size: 14px !important;
        }
        li {
          width: calc((100% - 5%) / 2);
          margin-right: 5%;
          &:nth-of-type(even) {
            margin-right: 0;
          }
        }
      }
    }
    @media screen and (max-width: 500px) {
      .core {
        margin-bottom: 40px;
        &_content {
          grid-template-columns: repeat(1, 100%);
          li {
            height: 200px;
            margin-top: 40px;

            i {
              width: 40px;
              height: 40px;
              line-height: 40px;
              font-size: 20px;
            }
          }
        }
      }
      .edu_type {
        margin-bottom: 30px;
        &_content {
          padding-bottom: 0;
          margin-bottom: 30px;
          margin-top: 30px;
          li {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
